<template>
  <div>
    <div class="layout">
      <div class="title">
        <van-icon name="arrow-left" class="icon-left" @click="lastStep" />
        <span>每日营业</span>
      </div>
      <div>
        <div class="row-layout">
          <span>每日营业额</span>
          <div>
            <span>￥</span>
            <input
              type="text"
              class="input"
              @change="setShopSet"
              v-model="setInfo.performance"
            />
          </div>
        </div>
        <div class="row-layout">
          <span>锁客人数</span>
          <input
            type="text"
            class="input"
            @change="setShopSet"
            v-model="setInfo.fans_num"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getShopSet, setShopSet } from "@/api/shop";
import { Toast } from "vant";
export default {
  name: "sell-supervise",
  data() {
    return {
      setShopId:
        this.$route.query.setShopId === undefined
          ? 0
          : this.$route.query.setShopId,
      setInfo: {
        fans_num: "",
        performance: "",
      },
    };
  },
  async mounted() {
    const res = await getShopSet({ set_shop_id: this.setShopId });
    if (res.code * 1 === 1) {
      this.setInfo = res.data;
    }
  },
  watch: {
    "setInfo.fans_num": function () {
      if (this.setInfo.fans_num * 1 <= 0) {
        this.setInfo.fans_num = "";
      }
    },
    "setInfo.performance": function () {
      if (this.setInfo.performance * 1 <= 0) {
        this.setInfo.performance = "";
      }
    },
  },
  methods: {
    async setShopSet() {
      let setInfo = this.setInfo;
      setInfo.set_shop_id = this.setShopId;
      const res = await setShopSet(setInfo);
      if (res.code * 1 === 1) {
        // Toast.success(res.msg)
      } else {
        Toast.fail(res.msg);
      }
    },
    lastStep() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.layout {
  background-color: #fff;
  height: 100vh;
}

.title {
  position: relative;
  text-align: center;
  padding: 12px 0;
  border-bottom: 1px solid #ededed;
  font-size: 21px;
  .icon-left {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
}

// 营业额样式
.row-layout {
  padding: 16px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ededed;
  font-weight: 600;
  box-sizing: border-box;

  .input {
    margin-left: 10px;
    width: 78px;
    height: 28px;
  }
}
</style>
